
























import {
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfHeading, SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'FilterBox',
  props: {
    open: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    hideOnMobile: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SfHeading,
    SfButton
  },
  setup (props) {
    const { app: { $wm } } = useContext();
    const isOpen = ref(props.open);

    const closeIcon = $wm.catalog.filterCloseIcon ?? 'xmark';
    const openIcon = $wm.catalog.filterOpenIcon ?? 'chevron-right';

    return {
      isOpen,
      closeIcon,
      openIcon
    }
  }
})
