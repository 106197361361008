


















import {
  computed,
  defineComponent, inject, PropType, useContext,
} from '@nuxtjs/composition-api';
import FilterBox from 'components/catalog/Category/Filters/Renderer/FilterBox.vue';
import { SfRadio } from '@storefront-ui/vue';
import type { Aggregation, AggregationOption } from '@wemade-vsf/magento-api';

export default defineComponent({
  components: {
    FilterBox,
    SfRadio
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
    open: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { app: { i18n } } = useContext();
    const { isFilterSelected } = inject('UseFiltersProvider');
    const selected = computed(() => ((id: string, optVal: string) => isFilterSelected(id, optVal)));
    const label = (option: AggregationOption) => `${(option.value === '1' ? i18n.t('Yes') : i18n.t('No'))}`;
    return { selected, label };
  },
});
